module services {
    export module tariff {
        export class tariffCountryGroupService implements interfaces.tariff.ITariffCountryGroupService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getCountryGroupDropDownList(): ng.resource.IResourceClass<interfaces.tariff.CountryGroupDropDown[]> {
               
                    return this.$resource<interfaces.tariff.CountryGroupDropDown[]>(this.ENV.DSP_URL + "TariffCountryGroup/GetCountryGroupDropDownList", {
                        countryGroupSearch: "@countryGroupSearch",
                    }, {
                            save: {
                                method: 'POST',
                                isArray: true,
                                interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                            }
                        });
            }

            //Gets list of all Country Groups for a given country
            getDropdownList(tariffCountryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffCountryGroup/GetDropdownList", {
                    tariffCountryId: tariffCountryId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            //Gets valid country groups based on target country.i.e.Country of Origin on Imports and Country of Destination on Exports.
            getForDropDownByTargetCountry(tariffCountryId: number, targetCountryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffCountryGroup/GetForDropDownByTargetCountry", {
                    tariffCountryId: tariffCountryId,
                    targetCountryId: targetCountryId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

        }
    }
    angular.module("app").service("tariffCountryGroupService", services.tariff.tariffCountryGroupService);
}